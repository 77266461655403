<template>
  <div class="module8">
    <div class="page0-title">
      <span style="display: flex; align-items: center;"
        ><img
          class="title-icon"
          src="../../../assets/images/title-icon.png"
          alt=""
          srcset=""
        />货源信息</span
      >
    </div>

    <div class="module8-content">
      <div class="module8-item">
        <div class="module8-item-1">
          <img
            src="./images/module8-icon.png"
            class="module8-item-icon"
            alt=""
            srcset=""
          />
        </div>

        <div class="module8-item-2">
          <div class="module8-item-2-top">
            货源总数
          </div>
          <div class="module8-item-bottom">
            <div style="display: inline-block;" :class="className">347</div>
            <span class="module8-item-side">万</span>
          </div>
        </div>
        <div class="module8-item-3">
          <div class="module8-item-3-top">
            货源吞吐量
          </div>
          <div class="module8-item-bottom">
            <div style="display: inline-block;" :class="className">7,351</div>
            <span class="module8-item-side">万条</span>
          </div>
        </div>
      </div>

      <div class="module8-item">
        <div class="module8-item-1">
          <img
            src="./images/module8-icon.png"
            class="module8-item-icon"
            alt=""
            srcset=""
          />
        </div>

        <div class="module8-item-2">
          <div class="module8-item-2-top">
            运输订单
          </div>
          <div class="module8-item-bottom">
            <div style="display: inline-block;" :class="className">30</div>
            <span class="module8-item-side">万</span>
          </div>
        </div>
        <div class="module8-item-3">
          <div class="module8-item-3-top" style="color: #00E9E3;">
            已完成
          </div>
          <div class="module8-item-bottom">
            <div style="display: inline-block;" :class="className">24</div>
            <span class="module8-item-side">万</span>
          </div>
        </div>
      </div>
    </div>

    <span class="angle1"></span>
    <span class="angle2"></span>
    <span class="angle3"></span>
    <span class="angle4"></span>
  </div>
</template>

<script>
export default {
  data() {
    return { numberAnimation: "" };
  },
  computed: {
    className() {
      return `number-font font-size-28 ${this.numberAnimation}`;
    },
  },
  methods: {
    setChart() {
      this.numberAnimation = "";
      setTimeout(() => {
        this.numberAnimation = "number-animation";
      }, 100);
    },
  },
  mounted() {
    this.setChart();
  },
};
</script>

<style lang="less" scoped>
.module8 {
  position: relative;
  border: 1px solid #0d2451;
  height: 100%;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(60px);

  &-content {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding: 12px 10px 5px;
    flex: 1;
  }

  &-item {
    text-align: center;
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    &-1 {
      // background-image: linear-gradient(45deg,#bfd5ea,#eaf1f8);
    }
    &-2 {
      &-top {
        color: #ffa755;
        font-size: 15px;
      }
    }
    &-3 {
      &-top {
        color: #20a6ff;
        font-size: 15px;
      }
    }

    &-bottom {
      font-weight: 900;
      font-size: 28px;
      color: #fff;
    }
    &-side {
      font-weight: 400px;
      font-size: 15px;
      margin-left: 5px;
      display: inline-block;
    }

    &-icon {
      width: 53px;
      height: 50px;
    }
  }
}
</style>
