var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module2"},[_vm._m(0),_c('div',{staticClass:"module2-content"},[_c('div',{staticClass:"module2-line"},[_vm._m(1),_c('div',[_c('span',{class:_vm.className,staticStyle:{"display":"inline-block"}},[_vm._v("562")]),_c('span',{staticClass:"number-font-side font-size-14"},[_vm._v("万")])])]),_c('div',{staticClass:"module2-line"},[_vm._m(2),_c('div',[_c('span',{class:_vm.className,staticStyle:{"display":"inline-block"}},[_vm._v("113")]),_c('span',{staticClass:"number-font-side font-size-14"},[_vm._v("万")])])]),_c('div',{staticClass:"module2-line"},[_vm._m(3),_c('div',[_c('span',{class:_vm.className,staticStyle:{"display":"inline-block"}},[_vm._v("112")]),_c('span',{staticClass:"number-font-side font-size-14"},[_vm._v("万")])])]),_c('div',{staticClass:"module2-line"},[_vm._m(4),_c('div',[_c('span',{class:_vm.className,staticStyle:{"display":"inline-block"}},[_vm._v("106")]),_c('span',{staticClass:"number-font-side font-size-14"},[_vm._v("万")])])]),_c('div',{staticClass:"module2-line"},[_vm._m(5),_c('div',[_c('span',{class:_vm.className,staticStyle:{"display":"inline-block"}},[_vm._v("100")]),_c('span',{staticClass:"number-font-side font-size-14"},[_vm._v("万")])])]),_c('div',{staticClass:"module2-line"},[_vm._m(6),_c('div',[_c('span',{class:_vm.className,staticStyle:{"display":"inline-block"}},[_vm._v("5.5")]),_c('span',{staticClass:"number-font-side font-size-14"},[_vm._v("万")])])]),_c('div',{staticClass:"module2-line"},[_vm._m(7),_c('div',[_c('span',{class:_vm.className,staticStyle:{"display":"inline-block"}},[_vm._v("3.6")]),_c('span',{staticClass:"number-font-side font-size-14"},[_vm._v("万")])])])]),_c('span',{staticClass:"angle1"}),_c('span',{staticClass:"angle2"}),_c('span',{staticClass:"angle3"}),_c('span',{staticClass:"angle4"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page0-title"},[_c('span',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticClass:"title-icon",attrs:{"src":require("../../../assets/images/title-icon.png"),"alt":"","srcset":""}}),_vm._v("APP下载数据")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module2-line-left"},[_c('img',{staticClass:"auth-icon",attrs:{"src":require("./images/app1.png"),"alt":"","srcset":""}}),_c('span',[_vm._v("华为应用市场")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module2-line-left"},[_c('img',{staticClass:"auth-icon",attrs:{"src":require("./images/app2.png"),"alt":"","srcset":""}}),_c('span',[_vm._v("小米应用市场")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module2-line-left"},[_c('img',{staticClass:"auth-icon",attrs:{"src":require("./images/app3.png"),"alt":"","srcset":""}}),_c('span',[_vm._v("应用宝")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module2-line-left"},[_c('img',{staticClass:"auth-icon",attrs:{"src":require("./images/app4.png"),"alt":"","srcset":""}}),_c('span',[_vm._v("魅族应用市场")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module2-line-left"},[_c('img',{staticClass:"auth-icon",attrs:{"src":require("./images/app5.png"),"alt":"","srcset":""}}),_c('span',[_vm._v("360应用市场")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module2-line-left"},[_c('img',{staticClass:"auth-icon",attrs:{"src":require("./images/app6.png"),"alt":"","srcset":""}}),_c('span',[_vm._v("oppo应用市场")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module2-line-left"},[_c('img',{staticClass:"auth-icon",attrs:{"src":require("./images/app7.png"),"alt":"","srcset":""}}),_c('span',[_vm._v("vivo应用市场")])])
}]

export { render, staticRenderFns }