<template>
  <div class="module9">
    <div class="page0-title">
      <span style="display: flex; align-items: center;"
        ><img
          class="title-icon"
          src="../../../assets/images/title-icon.png"
          alt=""
          srcset=""
        />其他板块</span
      >
      <!-- <Select
        v-model="model2"
        size="small"
        style="width:100px"
        @on-change="typeChange(item)"
      >
        <Option
          v-for="item in categories"
          :value="item.value"
          :key="item.value"
          >{{ item.label }}</Option
        >
      </Select> -->
    </div>

    <div class="module9-content">
      <div class="module9-item">
        <div :class="className">
          76,532
        </div>
        <div class="module9-item-bottom">
          维修商家
        </div>
      </div>
      <div class="module9-item">
        <div :class="className">
          25,461
        </div>
        <div class="module9-item-bottom">
          机械租赁
        </div>
      </div>
      <div class="module9-item">
        <div :class="className">
          2,192
        </div>
        <div class="module9-item-bottom">
          商场商户
        </div>
      </div>
    </div>

    <span class="angle1"></span>
    <span class="angle2"></span>
    <span class="angle3"></span>
    <span class="angle4"></span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      numberAnimation: "",
      model2: "1",
      categories: [
        {
          label: "机械租赁",
          value: "1",
        },
      ],
    };
  },
  computed: {
    className() {
      return `module9-item-top ${this.numberAnimation}`;
    },
  },
  methods: {
    typeChange() {},
    setChart() {
      this.numberAnimation = "";
      setTimeout(() => {
        this.numberAnimation = "number-animation";
      }, 100);
    },
  },
  mounted() {
    this.setChart();
  },
};
</script>

<style lang="less" scoped>
.module9 {
  position: relative;
  border: 1px solid #0d2451;
  height: 100%;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(60px);

  &-content {
    display: flex;
    justify-content: space-around;
    padding: 12px 10px 5px;
    flex: 1;
  }

  &-item {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    &-top {
      color: #ffffff;
      font-size: 24px;
      font-weight: 900;
    }
    &-bottom {
      color: rgba(255, 255, 255, 0.6);
      font-size: 14px;
    }
  }
}
</style>
