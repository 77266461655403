<template>
  <div class="module1">
    <div class="module1-wrapper">
      <div class="page0-title">
        <span style="display: flex; align-items: center;"
          ><img
            class="title-icon"
            src="../../../assets/images/title-icon.png"
            alt=""
            srcset=""
          />平台用户</span
        >
        <!-- <Select
          v-model="model2"
          size="small"
          style="width:100px"
          @on-change="typeChange(item)"
        >
          <Option
            v-for="item in userTypes"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</Option
          >
        </Select> -->
      </div>

      <div class="module1-line">
        <div class="module1-line-left">
          <img class="auth-icon" src="./images/user1.png" alt="" srcset="" />
          <span>注册用户</span>
        </div>
        <div>
          <span style="display: inline-block;" :class="className">428</span>
          <span class="number-font-side font-size-14">万</span>
        </div>
      </div>

      <div class="module1-line">
        <div class="module1-line-left">
          <img class="auth-icon" src="./images/user2.png" alt="" srcset="" />
          <span>已认证</span>
        </div>
        <div>
          <span style="display: inline-block;" :class="className">287</span>
          <span class="number-font-side font-size-14">万</span>
        </div>
      </div>

      <div class="module1-line">
        <div class="module1-line-left">
          <img class="auth-icon" src="./images/user3.png" alt="" srcset="" />
          <span>货主</span>
        </div>
        <div>
          <span style="display: inline-block;" :class="className">15.5</span>
          <span class="number-font-side font-size-14">万</span>
        </div>
      </div>

      <div class="module1-line">
        <div class="module1-line-left">
          <img class="auth-icon" src="./images/user4.png" alt="" srcset="" />
          <span>司机</span>
        </div>
        <div>
          <span style="display: inline-block;" :class="className">35.4</span>
          <span class="number-font-side font-size-14">万</span>
        </div>
      </div>
    </div>

    <!-- <div class="line-chart">
      <div class="switchButton">
        <a :class="{ active: value == 1 }" @click="handleSelect(1)">今天</a>
        <a :class="{ active: value == 2 }" @click="handleSelect(2)">本周</a>
        <a :class="{ active: value == 3 }" @click="handleSelect(3)">本月</a>
        <a :class="{ active: value == 4 }" @click="handleSelect(4)">本年</a>
      </div>
      <div id="module1-chart"></div>
    </div> -->
    <span class="angle1"></span>
    <span class="angle2"></span>
    <span class="angle3"></span>
    <span class="angle4"></span>
  </div>
</template>

<script>
const weeks = ["一", "二", "三", "四", "五", "六", "日"];
export default {
  data() {
    return {
      value: 1,
      model2: "",
      userTypes: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "车主",
          value: "1",
        },
        {
          label: "货主",
          value: "2",
        },
      ],
      numberAnimation: "",
    };
  },
  computed: {
    className() {
      return `number-font font-size-26 ${this.numberAnimation}`;
    },
    xAxisData() {
      let curYear = new Date().getFullYear();
      let curMonth = new Date().getMonth();
      let result = [];
      if (this.value == 1) {
        result = [];
        for (let index = 0; index < 24; index++) {
          const h = String(index).length == 1 ? `0${index}:00` : `${index}:00`;
          result.push(h);
        }
      }

      if (this.value == 2) {
        result = [];
        for (let index = 0; index < 7; index++) {
          result.push(weeks[index]);
        }
      }

      if (this.value == 3) {
        result = [];
        let dateLength = new Date(curYear, curMonth, 0).getDate();
        for (let i = 0; i < dateLength; i++) {
          result.push(i + 1 + "号");
        }
      }

      if (this.value == 4) {
        result = [];
        for (let index = 1; index < 13; index++) {
          result.push(`${index}月`);
        }
      }

      return result;
    },
    seriesData() {
      let curYear = new Date().getFullYear();
      let curMonth = new Date().getMonth();
      let result = [];
      if (this.value == 1) {
        result = [];
        for (let index = 0; index < 24; index++) {
          result.push(Math.ceil(Math.random() * 1000));
        }
      }
      if (this.value == 2) {
        result = [];
        for (let index = 0; index < 7; index++) {
          result.push(Math.ceil(Math.random() * 1000));
        }
      }
      if (this.value == 3) {
        result = [];
        for (
          let index = 0;
          index < new Date(curYear, curMonth, 0).getDate();
          index++
        ) {
          result.push(Math.ceil(Math.random() * 1000));
        }
      }
      if (this.value == 4) {
        result = [];
        for (let index = 0; index < 12; index++) {
          result.push(Math.ceil(Math.random() * 1000));
        }
      }
      return result;
    },
    option() {
      return {
        grid: [
          {
            show: false,
            left: "2%",
            top: "15%",
            right: "5%",
            bottom: 0,
            containLabel: true,
          },
        ],
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "none",
          },
          backgroundColor: "#11367a",
          textStyle: {
            color: "#6dd0e3",
            fontSize: 10,
          },
        },
        xAxis: [
          {
            type: "category",
            data: this.xAxisData,
            boundaryGap: false,
            axisLabel: {
              fontSize: 9,
              color: "#75deef",
              interval: 0,
              showMaxLabel: true,
              showMinLabel: true,
              formatter: (value, index) => {
                if (weeks.includes(value)) {
                  return value;
                }
                if (this.value == 1 || this.value == 4) {
                  if (index % 2 == 1) {
                    return value;
                  }
                } else {
                  if ((index + 1) % 5 == 0) {
                    return value;
                  }
                }
                // if (index == 0) {
                //   return value;
                // }
              },
            },
            axisLine: {
              lineStyle: {
                color: "#1a3c58",
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: true,
              inside: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: false,
            },
            // min: 0,
            // max: 300,
            splitNumber: 5,
            axisLabel: {
              showMaxLabel: false,
              fontSize: 9,
              color: "#75deef",
            },
            // name: "(笔)",
            nameGap: -5,
            nameTextStyle: {
              color: "#75deef",
              fontSize: 9,
              align: "right",
              padding: [0, 6, 0, 0],
            },
            axisLine: {
              lineStyle: {
                color: "#1a3c58",
              },
            },
            axisTick: {
              length: 3,
            },
          },
        ],
        series: {
          type: "line",
          symbol: "none",
          smooth: true,
          color: "#55FFE0",
          data: this.seriesData,
        },
      };
    },
  },
  methods: {
    handleSelect(val) {
      this.value = val;
      this.setChart();
    },
    setChart() {
      // let myChart = this.$echarts(document.getElementById("module1-chart"));
      // myChart.clear();
      // myChart.resize();
      // myChart.setOption(this.option);
      this.numberAnimation = "";
      setTimeout(() => {
        this.numberAnimation = "number-animation";
      }, 100);
    },
    typeChange() {
      this.setChart();
    },
  },
  mounted() {
    this.handleSelect(this.value);
    // this.setChart ();
  },
};
</script>

<style lang="less" scoped>
.module1 {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #0d2451;
  padding: 0 10px 5px;
  backdrop-filter: blur(60px);
  z-index: 99;

  &-line {
    padding: 0px 15px;
    height: 18%;
    margin: 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    background: linear-gradient(
      180deg,
      rgba(0, 177, 222, 0.1) 0%,
      rgba(0, 177, 222, 0.1) 100%
    );

    &-left {
      font-size: 16px;
      display: flex;
      align-items: center;
    }
    .auth-icon {
      width: 30px;
      height: 30px;
      margin-right: 14px;
    }
  }

  .line-chart {
    position: relative;
    height: 50%;
    width: 100%;
    flex: 1;
  }
  #module1-chart {
    height: 100%;
    width: 100%;
  }
}

.module1-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
