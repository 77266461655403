<template>
  <div class="module2">
    <div class="page0-title">
      <span style="display: flex; align-items: center;"
        ><img
          class="title-icon"
          src="../../../assets/images/title-icon.png"
          alt=""
          srcset=""
        />APP下载数据</span
      >
    </div>

    <div class="module2-content">
      <div class="module2-line">
        <div class="module2-line-left">
          <img class="auth-icon" src="./images/app1.png" alt="" srcset="" />
          <span>华为应用市场</span>
        </div>
        <div>
          <span style="display: inline-block;" :class="className">562</span>
          <span class="number-font-side font-size-14">万</span>
        </div>
      </div>
      <div class="module2-line">
        <div class="module2-line-left">
          <img class="auth-icon" src="./images/app2.png" alt="" srcset="" />
          <span>小米应用市场</span>
        </div>
        <div>
          <span style="display: inline-block;" :class="className">113</span>
          <span class="number-font-side font-size-14">万</span>
        </div>
      </div>
      <div class="module2-line">
        <div class="module2-line-left">
          <img class="auth-icon" src="./images/app3.png" alt="" srcset="" />
          <span>应用宝</span>
        </div>
        <div>
          <span style="display: inline-block;" :class="className">112</span>
          <span class="number-font-side font-size-14">万</span>
        </div>
      </div>
      <div class="module2-line">
        <div class="module2-line-left">
          <img class="auth-icon" src="./images/app4.png" alt="" srcset="" />
          <span>魅族应用市场</span>
        </div>
        <div>
          <span style="display: inline-block;" :class="className">106</span>
          <span class="number-font-side font-size-14">万</span>
        </div>
      </div>
      <div class="module2-line">
        <div class="module2-line-left">
          <img class="auth-icon" src="./images/app5.png" alt="" srcset="" />
          <span>360应用市场</span>
        </div>
        <div>
          <span style="display: inline-block;" :class="className">100</span>
          <span class="number-font-side font-size-14">万</span>
        </div>
      </div>
      <div class="module2-line">
        <div class="module2-line-left">
          <img class="auth-icon" src="./images/app6.png" alt="" srcset="" />
          <span>oppo应用市场</span>
        </div>
        <div>
          <span style="display: inline-block;" :class="className">5.5</span>
          <span class="number-font-side font-size-14">万</span>
        </div>
      </div>
      <div class="module2-line">
        <div class="module2-line-left">
          <img class="auth-icon" src="./images/app7.png" alt="" srcset="" />
          <span>vivo应用市场</span>
        </div>
        <div>
          <span style="display: inline-block;" :class="className">3.6</span>
          <span class="number-font-side font-size-14">万</span>
        </div>
      </div>
    </div>

    <span class="angle1"></span>
    <span class="angle2"></span>
    <span class="angle3"></span>
    <span class="angle4"></span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      numberAnimation: "",
    };
  },
  computed: {
    className() {
      return `module2-item-top module2-number-font font-size-24 ${this.numberAnimation}`;
    },
  },
  methods: {
    setChart() {
      this.numberAnimation = "";
      setTimeout(() => {
        this.numberAnimation = "number-animation";
      }, 100);
    },
  },
  mounted() {
    this.setChart();
  },
};
</script>

<style lang="less" scoped>
.module2 {
  position: relative;
  border: 1px solid #0d2451;
  height: 100%;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(60px);
  z-index: 99;
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 7px 10px 5px;
    flex: 1;
  }

  &-item {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    &-top {
      color: #ffffff;
      // font-size: 22px;
      // font-weight: 900;
      font-weight: 400;
    }
    &-bottom {
      color: rgba(255, 255, 255, 0.6);
      font-size: 14px;
    }
  }
}
.module2-line {
  width: 100%;
  padding: 0px 15px;
  height: 13%;
  margin: 1% 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  background: linear-gradient(
    180deg,
    rgba(0, 177, 222, 0.1) 0%,
    rgba(0, 177, 222, 0.1) 100%
  );

  &-left {
    font-size: 15px;
    display: flex;
    align-items: center;
  }
  .auth-icon {
    width: 26px;
    height: 26px;
    margin-right: 14px;
  }
}
.module2-number-font {
  font-family: SourceHanSansCN-Normal;
  font-weight: 600;
}
</style>
