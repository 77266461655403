<template>
  <div class="module4">
    <div class="module4-info">
      <div class="module4-title">
        用户总数(人)
      </div>
      <div class="module4-content">
        <div class="module4-content-left">
          <div class="module4-content-1">
            <div style="display: inline-block;" :class="className">12,278,638</div>
            <span class="module4-content-side">人</span>
          </div>
          <div style="padding: 0 0 0px 10px;">
            <img
              class="up-arrow"
              src="./images/up-arrow.png"
              alt=""
              srcset=""
            />
          </div>
          <div class="module4-content-2">
            <span>较昨日</span>
            <span>+3%</span>
          </div>
        </div>
      </div>
    </div>

    <div id="module4-chart" class="module4-chart"></div>

    <span class="angle1"></span>
    <span class="angle2"></span>
    <span class="angle3"></span>
    <span class="angle4"></span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: 1,
      seriesData: [311, 125, 256, 234, 672, 33],
      numberAnimation: "",
    };
  },
  computed: {
    className() {
      return `number-font ${this.numberAnimation}`;
    },
    option() {
      return {
        grid: [
          {
            show: false,
            left: "2%",
            top: "15%",
            right: "5%",
            bottom: 0,
            containLabel: true,
          },
        ],
        xAxis: [
          {
            show: false,
            data: [1, 2, 3, 4, 5, 6],
          },
        ],
        yAxis: [
          {
            show: false,
          },
        ],
        series: {
          type: "line",
          color: "#55FFE0",
          symbol: "none",
          smooth: true,
          data: this.seriesData,
        },
      };
    },
  },
  methods: {
    setChart() {
      let myChart = this.$echarts(document.getElementById("module4-chart"));
      myChart.clear();
      myChart.resize();
      myChart.setOption(this.option);
      this.numberAnimation = "";
      setTimeout(() => {
        this.numberAnimation = "number-animation";
      }, 100);
    },
  },
  mounted() {
    this.setChart();
  },
};
</script>

<style lang="less" scoped>
.module4 {
  position: relative;
  display: flex;
  justify-content: space-between;
  border: 1px solid #0d2451;
  height: 100%;
  margin-bottom: 5px;
  padding: 11px;
  width: calc(100%-26px);
  height: 100%;
  backdrop-filter: blur(60px);
  z-index: 99;
  &-info {
    flex-direction: column;
    display: flex;
    height: 100%;
    flex: 1;
  }

  &-title {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
  }
  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    flex: 1;

    &-left {
      display: flex;
      align-items: flex-end;
    }

    &-1 {
      color: #5be4e8;
      font-weight: 900;
      font-size: 36px;
      line-height: 36px;
      display: flex;
      align-items: flex-end;
      margin-right: 8px;
    }
    &-side {
      color: #ffffff;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      display: inline-block;
      margin: 0 0 5px 8px;
    }

    &-2 {
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      margin: 0 0 5px 8px;
    }

    .up-arrow {
      width: 18px;
    }
  }
  .module4-chart {
    width: 30%;
    height: 100%;
  }
}
</style>
