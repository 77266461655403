<template>
  <div class="module5">
    <div class="module5-item">
      <div class="module5-item-header">
        平台运单总数(单)
      </div>
      <div class="module5-item-content">
        <span class="module5-item-content-1">1,055</span>
        <span class="module5-item-content-2">万</span>
      </div>

      <span class="angle1"></span>
      <span class="angle2"></span>
      <span class="angle3"></span>
      <span class="angle4"></span>
    </div>
    <div class="module5-item">
      <div class="module5-item-header">
        平台车辆总数(辆)
      </div>
      <div class="module5-item-content">
        <span class="module5-item-content-1">35.4</span>
        <span class="module5-item-content-2">万</span>
      </div>
      <span class="angle1"></span>
      <span class="angle2"></span>
      <span class="angle3"></span>
      <span class="angle4"></span>
    </div>
    <div class="module5-item">
      <div class="module5-item-header">
        APP下载总数
      </div>
      <div class="module5-item-content">
        <span class="module5-item-content-1">1,120</span>
        <span class="module5-item-content-2">万</span>
      </div>
      <span class="angle1"></span>
      <span class="angle2"></span>
      <span class="angle3"></span>
      <span class="angle4"></span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: 1,
      seriesData: [311, 125, 256, 234, 672, 33],
    };
  },
  computed: {
   
  },
  methods: {
    
  },
  mounted() {
  },
};
</script>

<style lang="less" scoped>
.module5 {
  display: flex;
  justify-content: space-between;
  width: calc(100%-26px);
  height: 100%;
  backdrop-filter: blur(60px);
  z-index: 99;
  &-item {
    width: 30%;
    height: 100%;
    position: relative;
    
    border: 1px solid #0d2451;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    &-header {
      color: #ffffff;
      font-size: 14px;
    }
    &-content {
      // flex: 1;
      display: flex;
      align-items: flex-end;

      &-1 {
        color: #5be4e8;
        font-size: 34px;
        font-weight: 900;
        margin-right: 8px;
        margin-top: 8px;
      }
      &-2 {
        color: #ffffff;
        font-size: 14px;
        padding-bottom: 8px;
      }
    }
  }
}
</style>
